
export default function GraphTest() {
    const buttons = Array.from({ length: 11 }, (_, i) => i + 1); // Array of 11 buttons
  const radius = 150; // Radius of the circle
  const centerX = 200; // X-coordinate of the circle center
  const centerY = 200; // Y-coordinate of the circle center
  const buttonSize = 30; // Size of the buttons

  return (
    <svg width={400} height={400}>
      {buttons.map((button, index) => {
        const angle = (index / (buttons.length - 1)) * Math.PI; // Full 180 degrees for top semicircle
        const x = centerX + radius * Math.cos(angle);
        const y = centerY - radius * Math.sin(angle); // Invert y for top quadrant

        return (
          <g key={button}>
            {/* Line from center to button */}
            <line
              x1={centerX}
              y1={centerY}
              x2={x}
              y2={y}
              stroke="#d0958a"
              strokeWidth="2"
            />
            {/* Button */}
            <circle
              cx={x}
              cy={y}
              r={buttonSize / 2}
              fill="#d0958a"
              onClick={() => {alert("kdk")}}
            />
            {/* Button text */}
            <text
              x={x}
              y={y + 5}
              fill="white"
              textAnchor="middle"
              fontSize="16"
              onClick={() => {alert("kdk")}}
            >
              {button}
            </text>
          </g>
        );
      })}
    </svg>
  );
}